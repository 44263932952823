<template>
  <v-system-bar app class="header" :class="{ shadow: !isLoginPage }">
    <v-container fluid>
      <v-row class="align-center">
        <v-col cols="auto">
          <v-row class="justify-end">
            <img
              src="@/assets/img/thin-logo.svg"
              alt="Thin Section Logo"
              style="height: 50px"
            >
          </v-row>
        </v-col>
        <v-col cols="auto">
          <span class="pl-5">Цифровой шлиф</span>
        </v-col>
        <v-col v-if="!isLoginPage" cols="auto" class="ml-auto">
          <HeaderNavigationButtons></HeaderNavigationButtons>
        </v-col>
      </v-row>
    </v-container>
  </v-system-bar>
</template>

<script>
import HeaderNavigationButtons from "@/components/header/HeaderNavigationButtons.vue";

export default {
  name: "Header",
  components: {HeaderNavigationButtons},
  computed: {
    isLoginPage() {
      return ['Login', 'Register'].includes(this.$router.currentRoute.name)
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  height: 85px !important;
  width: 100%;
  color: #FFF;
  background-color: $base-color;
  font-weight: bold;
  font-size: 25px;

  padding-left: 2rem;
  z-index: 1000;
}

.shadow {
  box-shadow: rgba(0, 0, 0, 0.3) 0 8px 16px -8px;
}
</style>