<template>
  <div v-if="!isUnauthorizedPage">
    <SearchModalWindow
      v-if="isActivated"
    ></SearchModalWindow>
    <span class="name-wrapper">
      {{ fullName }}
    </span>
    <v-icon
      color="white"
      large
    >
      mdi-account-outline
    </v-icon>
    <v-icon
      v-if="isActivated"
      color="white"
      large
      @click="goHome"
    >
      mdi-home-outline
    </v-icon>
    <v-icon
      color="white"
      large
      @click="logout"
    >
      mdi-exit-to-app
    </v-icon>
  </div>
  <div v-else>
    <a class="auth-link" @click="goLogin">
      Авторизация
    </a>
    <v-icon
      color="white"
      large
    >
      mdi-account-outline
    </v-icon>
  </div>
</template>

<script>
import SearchModalWindow from "@/components/modal/search/SearchModalWindow.vue";
import userService from "@/http/service/user-service";
import {mapState} from "vuex";

export default {
  name: "HeaderNavigationButtons",
  data() {
    return {}
  },
  components: {
    SearchModalWindow
  },
  computed: {
    ...mapState({
      user: state => state.user.userDetails,
    }),
    isActivated() {
      return this.user.isActivated;
    },
    fullName() {
      return `${this.user.firstName} ${this.user.lastName}`
    },
    isUnauthorizedPage() {
      const routeName = this.$router.currentRoute.name
      return routeName === 'Hello' || routeName === 'About'
    }
  },
  methods: {
    logout() {
      userService.logout().then(() => {
        window.localStorage.setItem("auth", JSON.stringify(false))
      }).finally(() => {
        this.$router.push({name: 'Login'})
      })
    },
    goHome() {
      if (this.$router.currentRoute.name !== 'Home') {
        this.$router.push({name: 'Home'})
      }
    },
    goLogin() {
      this.$router.push({name: 'Login'})
    },
  }
}
</script>

<style scoped lang="scss">

.name-wrapper {
  font-size: 20px !important;
  font-weight: 400;
}

.auth-link {
  font-size: 20px !important;
  font-weight: 400;
  text-decoration: none;
  color: white;
}

</style>