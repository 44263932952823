<template>
  <v-dialog
      v-model="value"
      max-width="40vw"
  >
    <template v-slot:activator="{ on }">
      <v-icon
          v-on="on"
          class="ml-auto"
          large
          color="white"
      >
        mdi-magnify
      </v-icon>
    </template>
    <v-card>
      <v-container class="search-container">
        <div class="text-center font-weight-bold text-h5">
          Поиск
        </div>

        <v-text-field
            class="mt-4 pa-0"
            prepend-inner-icon="mdi-magnify"
            v-model="searchPattern"
        ></v-text-field>


        <div class="search-results">
          <div v-if="filteredTectonics.length">
            <div class="search-title">Тектоническая приуроченность:</div>
            <div
                v-for="tectonic in filteredTectonics"
                :key="tectonic.id"
                class="search-item"
                @click="navigate(tectonic)"
            >
              {{tectonic.name}}
            </div>
          </div>

          <div v-if="filteredDeposits.length">
            <div class="search-title">Месторождение:</div>
            <div
                v-for="deposit in filteredDeposits"
                :key="deposit.id"
                class="search-item"
                @click="navigate(deposit)"
            >
              {{deposit.name}}
            </div>
          </div>

          <div v-if="filteredWells.length">
            <div class="search-title">Скважина:</div>
            <div
                v-for="well in filteredWells"
                :key="well.id"
                class="search-item"
                @click="navigate(well)"
            >
              {{well.name}}
            </div>
          </div>

          <div v-if="filteredSamples.length">
            <div class="search-title">Образец:</div>
            <div
                v-for="sample in filteredSamples"
                :key="sample.id"
                class="search-item"
                @click="navigate(sample)"
            >
              {{sample.name}}
            </div>
          </div>

          <div v-if="filteredThinSections.length">
            <div class="search-title">Шлиф:</div>
            <div
                v-for="thinSection in filteredThinSections"
                :key="thinSection.id"
                class="search-item"
                @click="navigate(thinSection)"
            >
              {{thinSection.name}}
            </div>
          </div>

          <div v-if="filteredThinSectionPlots.length">
            <div class="search-title">Участок шлифа:</div>
            <div
                v-for="thinSectionPlot in filteredThinSectionPlots"
                :key="thinSectionPlot.id"
                class="search-item"
                @click="navigate(thinSectionPlot)"
            >
              {{thinSectionPlot.name}}
            </div>
          </div>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "SearchModalWindow",
  data() {
    return {
      value: false,
      searchPattern: ''
    }
  },
  methods: {
    async navigate(item) {
      if (this.$route.path !== item.link) {
        await this.$router.push(item.link)
      }
      this.searchPattern = ''
      this.value = false
    },
  },
  computed: {
    ...mapState({
      tectonics: state => Object.values(state.navigation.tectonics),
      deposits: state => Object.values(state.navigation.mineralDeposits),
      wells: state => Object.values(state.navigation.wells),
      samples: state => Object.values(state.navigation.samples),
      thinSections: state => Object.values(state.navigation.thinSections),
      thinSectionPlots: state => Object.values(state.navigation.thinSectionPlots),
    }),

    filteredTectonics() {
      return this.tectonics.filter(item => item.name.toLowerCase()
          .includes(this.searchPattern.toLowerCase()))
    },
    filteredDeposits() {
      return this.deposits.filter(item => item.name.toLowerCase()
          .includes(this.searchPattern.toLowerCase()))
    },
    filteredWells() {
      return this.wells.filter(item => item.name.toLowerCase()
          .includes(this.searchPattern.toLowerCase()))
    },
    filteredSamples() {
      return this.samples.filter(item => item.name.toLowerCase()
          .includes(this.searchPattern.toLowerCase()))
    },
    filteredThinSections() {
      return this.thinSections.filter(item => item.name.toLowerCase()
          .includes(this.searchPattern.toLowerCase()))
    },
    filteredThinSectionPlots() {
      return this.thinSectionPlots.filter(item => item.name.toLowerCase()
          .includes(this.searchPattern.toLowerCase()))
    },
  },
}
</script>

<style scoped lang="scss">
.search-container {
  min-height: 80vh;
}

.search-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: .5rem;
}

.search-item {
  background-color: $base-color;
  color: white;
  margin-bottom: .2rem;
  border-radius: .2rem;
  padding: .4rem .4rem .4rem 1rem;
  font-size: 18px;
  &:hover {
    background-color: rgba(137, 99, 186, .8);
    color: white;
    cursor: pointer;
  }
}

.search-results {
  max-height: 60vh;
  overflow-x: auto;
}
</style>